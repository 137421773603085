import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  forwardRef,
  Inject,
  Renderer2,
  AfterViewChecked,
  OnDestroy,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '../service/validation.service';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';
import { JwtDecodeService } from '../service/jwt-decode.service';
import { RedirectionService } from '../service/redirection.service';
import { CognitoService } from '../service/cognito.service';
import { MaintenanceService } from '../service/maintenance.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { ApiService } from '../service/api.service';
import { MsalService } from '@azure/msal-angular';
import { SamlService } from '../service/saml.service';
import { AuthenticationResult } from '@azure/msal-browser';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

//User Object Declaration
interface IUser {
  email: string;
  password: string;
  code: string;
  name: string;
  // username?: string;
}

// Interface representing the contents of a JSON Web Token (JWT)
interface JwtContents {
  email: string;
  firstName: string;
  familyName: string;
  encodedAccessToken: string;
  encodedRefreshToken: string;
  sessionExpiry: number;
  language: string[];
  theme: string[];
  phoneNumber: number;
  typeMFA: string;
  roles: string[];
  userID: string;
  EID: string;
}

@Component({
  selector: 'app-login',
  template: '<h1>Action: {{action}}</h1>',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, AfterViewChecked, OnDestroy {
  // public currentUsername: string = '';

  public currentEmail: any;

  public ulock: string | boolean = true;

  // public urls: any;

  // public loadIframe: boolean = false;

  // Flag indicating whether the user is authenticated or not
  userAuthenticated: boolean = false;

  // Subscription object for managing the user's authentication status
  userAuthSubscription!: Subscription;

  // Title of the maintenance notice to be displayed to the user
  public tituloAviso: string = 'Scheduled Maintenance Notice';

  // Planned date and time for the scheduled maintenance in a readable format
  public plannedDate: string = `April 15, 2024  - 10:00 PM`;

  // Text of the maintenance notice informing users about the maintenance activities
  public textoAviso: string = `We're conducting scheduled maintenance. Some features may be temporarily unavailable.`;

  // Variable to store the language selected value
  public selectedLanguage: string = '';

  // Retrieve current language selected from local storage
  languageStatus: string = this.localStorageService.getItem('language');

  // Interface representing the structure of a user object
  public user: IUser = {
    email: '',
    password: '',
    code: '',
    name: '',
  };

  // Object representing the contents of a JSON Web Token (JWT) for a user
  public jwtData: JwtContents = {
    email: '',
    firstName: '',
    familyName: '',
    encodedAccessToken: '',
    encodedRefreshToken: '',
    sessionExpiry: 0,
    language: [''],
    theme: [''],
    phoneNumber: 0,
    typeMFA: '',
    roles: [''],
    userID: '',
    EID: 'string',
  };

  // Properties for managing view states
  themeStatusLogin: string = 'light';
  invalidLogin: boolean = true;
  emailExist: boolean = true;
  messageInvalidLogin: boolean = false;
  showModalEmail: boolean = false;
  emptyEmail: boolean = false;
  charactersChecked: boolean = false;
  lowerCaseChecked: boolean = false;
  upperCaseChecked: boolean = false;
  specialCharChecked: boolean = false;
  oneDigitChecked: boolean = false;
  showCheckBoxTip: boolean = true;
  showCheckBoxTipResetPw: boolean = false;
  charactersCheckmodel: boolean = false;
  lowerCaseCheckModel: boolean = false;
  upperCaseCheckModel: boolean = false;
  digitCheckModel: boolean = false;
  specialCharCheckModel: boolean = false;
  showPassword: boolean = false;
  showPasswordConfirmation: boolean = false;
  showOldPassword: boolean = false;
  loginActive: boolean = false;
  resetPasswordActive: boolean = false;
  userNewPasswordActive: boolean = false;
  loginGenericErrorMessage: boolean = false;

  newPasswordFormActive: boolean = false;
  login: string = '';
  password: string = '';
  email: string = '';
  resetCode: string = '';
  message: string = 'error';
  forgotPassword: boolean = false;
  forgotPasswordForm: boolean = false;

  // Properties for managing user inputs
  requestLogin: string = '';
  responce: string = '';
  teste: boolean = false;
  name: string = ';';
  showErrorModal = false;
  emailIsNotEmpty = true;
  requestNewPassword: string = '';
  requestNewPasswordConfirmation: string = '';
  showAlertStrong: boolean = false;
  showAlertMedium: boolean = false;
  showAlertWeak: boolean = false;
  invalidEmail: boolean = false;
  newPasswordConfirmed: boolean = false;
  show: boolean = false;
  userdigit: boolean = false;
  userid: string = 'a';

  // Properties of action put in url
  action: string | null = '';
  returnUrl: string | null = '';

  public MFACodeRequiredActive: boolean = false;
  public MFACodeSMS: string = '';
  public MFACodeTOTP: string = '';
  public MFACode: string = '';
  public wrongMFACode: boolean = false;
  public phone_number: string = '';
  public other_phone_number: string = '';
  public signUpMFAActive: boolean = false;
  public phoneAuthentificationActive: boolean = false;
  public nextTotpPage: boolean = false;
  public redirectUrl: any;

  public mfaChecked: boolean = false;
  // Reference to the toggle checkbox element in the template
  @ViewChild('toggleCheckbox') toggleCheckbox!: ElementRef<HTMLInputElement>;

  // Reference to the Multi-Factor Authentication (MFA) code input element in the template
  // The { static: false } option indicates that this query should not be resolved until after change detection runs
  @ViewChild('enterMFA', { static: false })
  mfaCodeInput!: ElementRef<HTMLInputElement>;

  // variables used in the email validation form
  public emailValidationActive: boolean = false;
  public confirmationCode: string = '';

  // Variable that old the system message that throw to the user
  private systemMessage: string = '';

  public loading: boolean = false;

  public delayTImer: number = 2000; // was 3k

  public customDelayTimer: number = 5000; // was 3k

  public maintenanceData: any;
  public showMaintenanceMessage: boolean = false;

  public jwtFromLocalStorage: any;

  public validUser: boolean = false;
  public accessToken: any = 0;
  public refreshToken: any = 0;

  public samlActive: boolean = true;

  public isHovered: boolean = false;
  public cName: string = '';
  public hostName: string = '';

  public tenantConfig: any;

  public autorization: boolean = false;

  public emailParaAuthenticacao: string = '';

  public inputLabel: string = 'cognitoLoginUsername';

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService))
    @Inject(forwardRef(() => TranslateService))
    private translate: TranslateService,
    private route: ActivatedRoute,
    private validate: ValidationService,
    public theme: ThemeService,
    private el: ElementRef,
    private renderer: Renderer2,
    private systemMessageService: SystemMessageService,
    public decodeTokenService: JwtDecodeService,
    private redirectionService: RedirectionService,
    public cognitoService: CognitoService,
    public maintenanceService: MaintenanceService,
    private apiService: ApiService,
    public authService: MsalService,
    public SAML: SamlService,
    private sanitizer: DomSanitizer
  ) {
    this.loading = true;

    // verify CNAME (url info)
    this.checkCName();
  }

  // Lifecycle hook that is called when the component is destroyed
  ngOnDestroy(): void {
    // Check if there is an active subscription for user authentication
    if (this.userAuthSubscription) {
      // Unsubscribe from the user authentication subscription to prevent memory leaks
      this.userAuthSubscription.unsubscribe();
    }
  }

  // Function to set the loading state and disable login functionality
  loadingFunc() {
    // Set the loading flag to true, indicating that an operation is in progress
    this.loading = true;

    // Disable the login feature by setting loginActive to false
    // This prevents user interaction during the loading process
    this.loginActive = false;
  }

   splitCredentials(input: string): [string, string] {
    const [part1, part2] = input.split('-');
    if (!part1 || !part2) {
        throw new Error("Input string does not contain two parts separated by '-'");
    }
    return [part1, part2];
}

  // ROUTINE EXECUTED WHEN THE COMPONENT/PAGE IS ACCESSED
  async ngOnInit(): Promise<void> {
    let user: string | null = null;
    let pass: string | null = null;
    let credentials;
    this.action = this.route.snapshot.paramMap.get('action');

// This code was used with the simplefied email that was block by spam
    // if (this.action === 'token') {
    //   credentials = this.route.snapshot.paramMap.get('credentials') ?? "";
    //   [user, pass] = this.splitCredentials(credentials);
    // }
    if(this.action === 'activate'){
      this.inputLabel = 'username';

      this.loginActive = true;
      this.loading = false;
    }
    else if (this.action === 'token' && user !== null && pass !== null) {
      this.user.email = user;
      this.user.password = pass;
      this.onSubmit();
    }
    else {
      this.loginActive = false;

      const userData = await this.localStorageService.getItem('jwtData');

      let ulock: string | boolean = true;
      if (userData) {
        const email = this.decodeTokenService.decodeToken(userData).email;
        ulock = await this.apiService.authenticateUser(email).then((res) => {
          return res;
        });
      }

      if (!ulock) {
        this.loginActive = true;
        this.loading = false;

        this.systemMessageService.selectRibbon('danger', 'ulock');
      } else {
        // Subscribe to the current configuration from the SAML service
        this.SAML.getCurrentConfig().subscribe((config) => {
          // Store the retrieved configuration in tenantConfig
          this.tenantConfig = config;
        });

        // Subscribe to the user authentication subject from the SAML service
        this.userAuthSubscription =
          this.SAML.userAuthenticatedSubject.subscribe(
            async (isAuthenticated) => {
              // Update the userAuthenticated flag based on the authentication status
              this.userAuthenticated = isAuthenticated;

              // Call the method to join the Nova service using Microsoft authentication
              this.joinNovaByMicrosoft();
            }
          );

        // Subscribe to changes in the current route URL
        this.route.url.subscribe(async (urlSegments) => {
          // Check if there are any URL segments present
          if (urlSegments.length > 0) {
            // Switch statement to handle different URL path actions
            switch (urlSegments[0].path) {
              case 'login':
                if (urlSegments[1].path === 'account-blocked') {
                  this.systemMessageService.selectRibbon(
                    'danger',
                    'accountNotAutorizedToSignIn'
                  );
                }
                if (urlSegments[1].path === 'user-blocked') {
                  this.systemMessageService.selectRibbon(
                    'danger',
                    'notAutorizedToSignIn'
                  );
                }
                break;

              // Handle the sign-up MFA action
              case 'sign-up-mfa':
                this.action = 'sign-up-mfa';
                break;

              // Handle the change password action with a redirect parameter
              case 'change-password&redirect=me':
                this.action = 'change-password&redirect=me';
                break;

              // Handle the sign-out action
              case 'signout':
                // Clear local storage to remove any user data
                localStorage.clear();
                // End the SAML login session
                this.SAML.endLogin();
                try {
                  // Wait for the logout to complete
                  await this.SAML.microsoft.logout();
                } catch (error) {
                  // Log any errors that occur during logout
                  console.error('Error when logging out:', error);
                }

                break;

              default:
                try {
                  // Initialize the SAML authentication process and wait for the response
                  const initializeResponse = await firstValueFrom(
                    this.SAML.microsoft.initialize()
                  );

                  // Attempt to perform a silent Single Sign-On (SSO) authentication
                  const ssoResponse: AuthenticationResult | undefined =
                    await firstValueFrom(this.authService.ssoSilent({}));

                  if (ssoResponse) {
                    // Authenticated user, continue with component logic
                    // Extract the email from the SSO response for further processing
                    this.emailParaAuthenticacao =
                      ssoResponse.account.username || '';

                    // Set the userAuthenticated flag to true to indicate the user is logged in
                    this.SAML.userAuthenticated = true;
                  } else {
                    // Log an error if the SSO response is undefined, indicating no authentication was performed
                    console.error('ssoResponse is undefined');
                  }
                } catch (error) {
                  // Handle any errors that occur during the authentication process
                  // Unauthenticated user is redirected to the login page
                  // ////console.log('User not authenticated:', error);
                }
                break;
            }
          }
        });

        try {
          // Retrieve the JWT data string from local storage
          const jwtDataString = await this.localStorageService.getItem(
            'jwtData'
          );

          // Store the retrieved JWT data in a class property for further use
          this.jwtFromLocalStorage = jwtDataString;
        } catch (error) {
          // Log any errors that occur during the retrieval process
          console.error(error);
        }

        try {
          // Attempt to retrieve the MSAL account keys from local storage
          await this.localStorageService.getItem('msal.account.keys');
        } catch (error) {
          // Log any errors that occur during the retrieval process
          console.error(error);
        }

        // Retrieve an array of all keys stored in local storage (debug)
        const keys = Object.keys(localStorage);

        try {
          // Attempt to confirm the validity of the user through the Cognito service
          this.validUser = await this.cognitoService.confirmValidUser();
        } catch (error) {
          // Log any errors that occur during the confirmation process
          console.error(error);
        }

        // CHECK IF HAS DATA IN THE LOCAL STORAGE
        if (this.jwtFromLocalStorage === null) {
          // IF THERE IS NO DATA IN THE LOCAL STORAGE, RUN LOGIN WORK FLOW

          // SHOW LOGIN SCREEN
          this.loading = false;
          this.loginActive = true;
        } else {
          // BECAUSE HAS DATA IN THE LOCAL STORAGE

          // SHOW LOADING SCREEN
          this.loading = true;

          // HIDE LOGIN FORM
          this.loginActive = false;

          // GET DECOED JWT TOKEN
          const dataToken = this.decodeTokenService.decodeToken(
            this.jwtFromLocalStorage
          );

          // Assign the expiration time of the access token from the received data
          this.accessToken = dataToken.accesstoken.exp;

          // Assign the expiration time of the refresh token from the received data
          this.refreshToken = dataToken.refresh_token.exp;

          // Validate the tokens by checking their expiration times
          const tokenValid = this.validateTokens(
            dataToken.accesstoken.exp,
            dataToken.refresh_token.exp
          );

          // PERFORM A WAIT
          await this.delay();

          switch (true) {
            case this.action === 'sign-up-mfa' && this.validUser && tokenValid:
              // SHOW SIGN UP MFA SCREEN
              this.signUpMFAActive = true;

              // HIDE LOADING SCREEN
              this.loading = false;

              // HIDE LOGIN FORM
              this.loginActive = false;

              // GENERATE QR CODE
              this.getMFARegisterCodeTOTP();

              break;

            case this.action === 'change-password&redirect=me' &&
              this.validUser &&
              tokenValid:
              // SHOW NEW PASSWORD FORM
              this.newPasswordFormActive = true;

              // HIDE LOGIN FORM
              this.loginActive = false;

              // SET USER DATA FROM LOCALSTORAGE TOKEN
              this.user.email = dataToken.email;
              this.user.name = dataToken.firstname + ' ' + dataToken.lastname;
              this.user.code = dataToken.eid;

              // HIDE LOADING SCREEN
              this.loading = false;

              break;

            case !tokenValid:
              // DESTROY JWT FROM LOCAL STORAGE
              this.localStorageService.clearItems();
              this.localStorageService.removeItem('jwtData');
              this.localStorageService.removeItem('jwtRedirect');

              // HIDE LOADING SCREEN
              this.loading = false;

              // SHOW LOGIN FORM
              this.loginActive = true;

              break;

            default:
              // oninit
              //console.log('redirected');
              this.redirectUsingLocalStorage();
              break;
          }
        }
      }
    }
  }

  checkCName() {
    // Retrieve the current hostname from the browser window
    this.hostName = window.location.hostname;

    // Define a regular expression to match the expected hostname format
    const regex = /^(.*?)\.profilnova\.com$/;
    // Attempt to match the hostname against the regex
    const match = this.hostName.match(regex);

    // Check if a match was found and if it contains a capturing group
    if (match && match[1]) {
      // Extract the subdomain (cName) from the match and assign it
      this.cName = match[1];
      this.SAML.cName = match[1];
    } else {
      // If no valid match was found, set cName to 'localhost' and log a message
      this.SAML.cName = 'localhost';
    }
  }

  // Function to handle mouse over event
  onMouseOver() {
    // Function to handle mouse over event
    this.isHovered = true;
  }

  // Function to handle mouse out event
  onMouseOut() {
    // Set the isHovered property to false when the mouse leaves the element
    this.isHovered = false;
  }

  async joinNovaByMicrosoft(emailAddress?: string) {
    const email = this.emailParaAuthenticacao;

    try {
      // AUTHENTICATES THE USER ON THE NOVA SERVER
      const redirectDestination = await this.apiService
        .authenticateUser(email)
        .then((res) => {
          return res;
        });
      if (!redirectDestination) {
        this.systemMessageService.selectRibbon('danger', 'you shall not pass');
      } else {
        // ADD JWT DATA STRING TO LOCAL STORAGE WITH jwtData KEY
        this.localStorageService.addItem('jwtData', this.apiService.token);
        this.localStorageService.addItem(
          'jwtRedirect',
          this.apiService.redirect
        );
        // on ms join
        this.redirectUsingLocalStorage();
      }
    } catch (error) {
      console.error('Error authenticating user::', error);
    }
  }

  async refreshNovaSession(emailAddress?: string) {
    let email = '';

    try {
      if (localStorage.getItem('jwtData')) {
        const localJWT = localStorage.getItem('jwtData');
        const JWT = this.decodeTokenService.decodeToken(localJWT || '') || '';
        email = emailAddress || JWT.email;
        //console.log('email', email, JWT.email);
      } else {
        const localJWT = '';
        email = emailAddress || '';
        //console.log('email', email);
      }

      // AUTHENTICATES THE USER ON THE NOVA SERVER
      const redirectDestination = await this.apiService
        .authenticateUser(email)
        .then((res) => {
          return res;
        })
        .catch((e) => {
          console.error('Error authenticating user:: 574->', e);

          throw new Error();
        });

      this.ulock = redirectDestination;

      // ADD JWT DATA STRING TO LOCAL STORAGE WITH jwtData KEY
      this.localStorageService.addItem('jwtData', this.apiService.token);

      this.localStorageService.addItem('jwtRedirect', this.apiService.redirect);

      // update redirect url
      this.redirectUrl = this.apiService.redirect;
    } catch (error) {
      console.error('Error authenticating user:: 619->', error);
    }
  }

  validateTokens(accesstoken: number, refreshTokenn: number) {
    // Get the current time in seconds since the Unix epoch
    const dataInSeconds = Math.floor(Date.now() / 1000);

    // Check the validity of the access token and refresh token using a switch statement
    switch (true) {
      // Case 1: If the access token is still valid (not expired)
      case accesstoken > dataInSeconds:
        return true;
        break;

      // Case 2: If the access token has expired but the refresh token is still valid
      case accesstoken < dataInSeconds && refreshTokenn > dataInSeconds:
        this.refreshNovaSession();
        return true;
        break;

      // Case 3: If both access and refresh tokens have expired
      case accesstoken < dataInSeconds && refreshTokenn < dataInSeconds:
        return false;
        break;

      // Default case: Fallback to false if none of the conditions above are met
      default:
        return false;
        break;
    }
  }

  ngAfterViewChecked(): void {
    if (this.mfaCodeInput) {
      // Set focus to the input field
      this.mfaCodeInput.nativeElement.focus();
    }
  }

  // This function must be adjusted according to the login portal specifications
  redirectToExternalUrl(url: string) {
    const params = {
      param1: 'value1',
      param2: 'value2',
    };
    this.redirectionService.redirectToUrlWithParams(url, params);
  }

  // Method to check for available maintenance data
  checkMaintenance(): void {
    // Get maintenance data from the maintenance service
    this.maintenanceData = this.maintenanceService.getMaintenanceData();

    // Check if maintenance data is empty or null
    if (this.maintenanceData === null || '') {
      // If there's no maintenance data, don't show the maintenance message
      this.showMaintenanceMessage = false;
    } else {
      // If there's maintenance data, show the maintenance message
      this.showMaintenanceMessage = true;
    }
  }

  // Change language based on user selection
  changeLanguage(language: string) {
    this.translate.use(language);
  }

  // Asynchronous method to introduce a delay
  async delay(): Promise<void> {
    // ////console.log("ENTROU DELAY")
    // Returning a Promise that resolves after a certain delay
    return new Promise((resolve) => {
      // Using setTimeout to introduce the delay
      setTimeout(() => {
        // Resolving the Promise after the delay
        resolve();
      }, this.delayTImer); // Delay time specified by this.delayTimer
    });
  }

  // Asynchronous method to introduce a delay
  async customDelay(): Promise<void> {
    // ////console.log("ENTROU DELAY")
    // Returning a Promise that resolves after a certain delay
    return new Promise((resolve) => {
      // Using setTimeout to introduce the delay
      setTimeout(() => {
        // Resolving the Promise after the delay
        resolve();
      }, this.customDelayTimer); // Delay time specified by this.delayTimer
    });
  }

  // REDIRECT USER USING URL AND PARAMS DATA
  getUrlParamsAndRedirect(redirectUrl: any, params: any) {
    this.redirectionService.redirectToUrlWithParams(redirectUrl, params);
  }

  resetToLoginForm() {
    // TURN OFF LOADING SCREEN
    this.loading = false;

    // SHOW LOGIN FORM
    this.loginActive = true;

    // REMOVE DATA/ITEMS FROM LOCAL STORAGE
    this.localStorageService.clearItems();
  }

  redirectToDestination(destination: string) {
    this.router.navigate([destination]);
  }

  // HANDLE FORM SUBMISSION FOR LOGIN
  async onSubmit() {
    // TURN ON LOADING SCREEN
    this.loading = true;

    // HIDE LOGIN FORM
    this.loginActive = false;

    // Check if either login or password is empty
    if (this.user.email === '' || this.user.password === '') {
      this.systemMessage = 'passwordUsernameEmpty';
    }
console.log('toto');
    // CALL THE AWS SERVICE RESPONSIBLE FOR LOGIN
    this.cognitoService
      .signIn(this.user)
      .then(async (signedIn) => {
        if (signedIn) {
          // OBTAINS THE EMAIL OF THE CONNECTED USER
          // NECESSARY FOR AUTHENTICATION IN THE SYSTEM
          await this.cognitoService.getUser().then((result) => {});
          this.emailParaAuthenticacao = this.user.email;

          try {
            //console.log(793, "refresh nova session")
            await this.refreshNovaSession(this.user.email);
            await this.delay();
          } catch (error) {
            console.error('Error: ', error);
          }

          // await this.delay();

          //Disable error messaging on successful login
          this.messageInvalidLogin = false;

          switch (signedIn) {
            // FIRST TIME SIGN-IN CASE - REQUIRES A PASSWORD CHANGE
            case 'change-password':
              // SHOW NEW PASSWORD FORM
              this.newPasswordFormActive = true;

              // HIDE LOADING SCREEN
              this.loading = false;

              // HIDE LOGIN FORM
              this.loginActive = false;

              break;

            // MFA SIGN-IN CASE
            case 'MFA-required':
              this.loading = false;
              // Set the variable to hide the login page
              this.loginActive = false;
              // Set the variable to show the MFA login page
              this.MFACodeRequiredActive = true;
              break;

            default:
              //Check if user has been signed in and an active session is opened.
              this.cognitoService
                .getUser()
                .then(async (response) => {
                  if (response) {
                    this.localStorageService.addItem(
                      'user',
                      response.attributes.given_name +
                        ' ' +
                        response.attributes.family_name
                    );
                    this.localStorageService.addItem(
                      'client',
                      response.attributes['custom:client_id']
                    );
                    if (this.localStorageService.getItem('user').length !== 0) {
                      this.cognitoService.getKeys();

                      // WITH USER AUTHENTICATED IN COGNITO AND NOVA SERVER,
                      //  APPLY REDIRECTION ROUTINE
                      // on submit
                      if (!this.ulock) {
                        this.loginActive = true;
                        this.loading = false;
                        this.systemMessageService.selectRibbon(
                          'danger',
                          'ulock'
                        );
                      } else {
                        this.redirectUsingLocalStorage();
                      }
                    }
                  } else {
                    // IF IT WAS UNABLE TO AUTHENTIFY THE USER

                    // APPLY A DELAY
                    await this.delay();

                    // HIDE LOADING SCREEN
                    this.loading = false;

                    // SHOW LOGIN FORM
                    this.loginActive = true;

                    // Set the system message to throw to the user
                    this.systemMessage = 'passwordUsernameIncorrect';
                    // throw the system message to the user
                    this.systemMessageService.selectRibbon(
                      'danger',
                      this.systemMessage
                    );
                  }
                })
                .catch(async (error) => {
                  // IF IT WAS UNABLE TO AUTHENTIFY THE USER

                  // SHOW CONSOLE ERROR
                  console.error('Error: ', error);

                  // APPLY A DELAY
                  await this.delay();

                  // HIDE LOADING SCREEN
                  this.loading = false;

                  // SHOW LOGIN FORM
                  this.loginActive = true;

                  // Set the system message to throw to the user
                  this.systemMessage = 'passwordUsernameIncorrect';

                  // throw the system message to the user
                  this.systemMessageService.selectRibbon(
                    'danger',
                    this.systemMessage
                  );
                });
              break;
          }
        } else {
          // IF IT WAS UNABLE TO AUTHENTIFY
          // THE USER BECAUSE USERNAME/PASSWORD INCORRECT

          // APPLY A DELAY
          await this.delay();

          // HIDE LOADING SCREEN
          this.loading = false;

          // SHOW LOGIN FORM
          this.loginActive = true;

          // DEFINE ERROR MESSAGE AS USERNAME/PASSWORD INCORRECT
          this.systemMessage = 'passwordUsernameIncorrect';

          // throw the system message to the user
          this.systemMessageService.selectRibbon('danger', this.systemMessage);
        }
      })
      .catch(async (error) => {
        // APPLY A DELAY
        await this.delay();

        // HIDE LOADING SCREEN
        this.loading = false;

        // SHOW LOGIN FORM
        this.loginActive = true;

        // Set the system message to throw to the user
        this.systemMessage = 'passwordUsernameIncorrect';

        // throw the system message to the user
        this.systemMessageService.selectRibbon('danger', this.systemMessage);

        //for debug purposes we log the aws error. Must be commented for production
        console.error('Error signing in:', error);
        //prevent further code from running
        return false;
      });
  }

  // Handle "Forgot Password" link click
  forgotPasswordClick() {
    this.loading = true;
    //Hide error message on login form: Something went wrong, please try again.
    this.loginGenericErrorMessage = false;

    this.user.email = this.email;
    // Set the loginActive flag to false to hide the login section
    this.loginActive = false;

    // Set the resetPasswordActive flag to true to show the reset password section
    this.resetPasswordActive = true;

    this.forgotPassword = true;
    this.loading = false;
  }

  async redirectUsingLocalStorage() {
    var redirectNew: any;

    if (this.localStorageService.getItem('jwtRedirect') && this.ulock) {
      //console.log(972, this.ulock)
      redirectNew = this.localStorageService.getItem('jwtRedirect');
    } else {
      if (!this.ulock) {
        this.systemMessageService.selectRibbon('danger', 'ulock');
      } else {
        this.systemMessage = 'redirectFailed';
        this.systemMessageService.selectRibbon('danger', this.systemMessage);
      }
      await this.delay();
      await this.delay();
      this.loading = false;
      this.loginActive = true;
      //this.router.navigateByUrl('/login');
      return;
    }

    // REDIRECT TO URL
    // Check if the user is authenticated using either Cognito or SAML
    if (
      (await this.cognitoService.confirmValidUser()) ||
      this.SAML.userAuthenticated
    ) {
      // If the user is authenticated, redirect to the specified external URL
      // this.redirectToExternalUrl(redirect);
      this.redirectToExternalUrl(redirectNew);
    } else {
      // If the user is not authenticated, navigate to the login page
      //this.router.navigateByUrl('/login');
      // Set loginActive to true to indicate the login process is active
      this.loginActive = true;
      // Stop loading state
      this.loading = false;
    }
  }

  // Handle the click event when the "Reset Password" button is clicked.
  async sendUserEmailClick() {
    // button reset password clicked

    // Check if email is empty.
    this.isEmptyEmail();

    if (!this.forgotPassword) {
      // Update view states based on email validity and existence
      if (
        !this.emptyEmail &&
        !this.invalidEmail &&
        this.email === this.user.email
      ) {
        // Set the new password form to be active and the reset password form to be inactive
        if (this.showCheckBoxTipResetPw) {
          this.newPasswordFormActive = false;
          this.resetPasswordActive = false;
        } else {
          this.newPasswordFormActive = true;
          this.resetPasswordActive = false;
        }
      } else {
        // Set the system message to throw to the user
        this.systemMessage = 'loginForgotPasswordAlertEmailInvalid';
        // throw the system message to the user
        this.systemMessageService.selectRibbon('danger', this.systemMessage);
        // Set the new password form to be inactive and the reset password form to be active
        this.newPasswordFormActive = false;
        this.resetPasswordActive = true;
      }
    } else {
      //Set user object property
      this.user.email = this.email;

      //Call Cognito Service
      //Set user object property
      this.user.email = this.email;

      if (
        !this.emptyEmail &&
        !this.invalidEmail &&
        this.email === this.user.email
      ) {
        //Call Cognito Service
        let sendEmail = await this.cognitoService.forgotPassword(this.email);
        // let sendEmail = true;

        if (sendEmail) {
          // Display forgot Password Form
          this.forgotPasswordForm = true;

          // Hide email form
          this.resetPasswordActive = false;

          //reset forgot password state
          this.forgotPassword = false;
        } else {
          // Hide email form
          this.resetPasswordActive = false;

          //reset forgot password state
          this.forgotPassword = false;

          //Back to login form
          this.loginActive = true;

          // Set the system message to throw to the user
          this.systemMessage = 'cognitoLoginErrorMessage';
          // throw the system message to the user
          this.systemMessageService.selectRibbon('danger', this.systemMessage);
        }
      }
    }
  }

  async submitPasswordReset() {
    this.loading = true;

    if (this.validate.strongPassword.test(this.user.password)) {
      //Send change request to Amazon
      var passwordReset = await this.cognitoService.resetPassword(
        this.user.email,
        this.user.code,
        this.user.password
      );
      // var passwordReset = false;

      if (passwordReset) {
        //on success
        //Hide reset form here
        this.forgotPasswordForm = false;
        this.resetPasswordActive = false;

        //Hide error message on login form: Something went wrong, please try again.
        this.loginGenericErrorMessage = false;
        await this.delay();

        this.loading = false;
        //Show Login Form
        this.loginActive = true;
      } else {
        // Active Login View
        this.loginActive = true;

        // Desactive forgotPasswordForm view
        this.forgotPasswordForm = false;

        // Desactive resetPasswordActive view
        this.resetPasswordActive = false;

        // Desactive newPasswordFormActive view
        this.newPasswordFormActive = false;

        //Display error message on login form: Something went wrong, please try again.
        this.loginGenericErrorMessage = true;

        this.loading = false;
      }
    } else {
      this.loading = false;
      this.showCheckBoxTipResetPw = true;
    }
    this.loading = false;
  }

  // Check if the email is empty and set the appropriate flag
  isEmptyEmail() {
    if (this.email === '') {
      // If the email is empty, set the emptyEmail flag to true
      this.emptyEmail = true;
    } else {
      if (this.user.email === '' || this.user.email === null) {
        this.user.email = this.email;
      }
      this.emptyEmail = false; // If the email is not empty, set the emptyEmail flag to false
    }
  }

  // Handle the click event when the "Confirm New Password" button is clicked.
  async confirmNewPasswordClick() {
    if (this.user.email === '') {
      // this.user.email = "mcaram@mwstudio.ca";
    }

    // Check if the new password matches the strong password criteria
    if (this.validate.strongPassword.test(this.requestNewPassword)) {
      try {
        if (this.action === 'change-password') {
          this.cognitoService.challengeName = '';
        }
        // Cognito functions to handle password change. Returns a False or Object
        const passwordChanged = await this.cognitoService.changePassword(
          this.user,
          this.requestNewPassword
        );
        if (passwordChanged) {
          // change made user issue
          // this.user.email = this.email;

          switch (this.action) {
            case 'change-password&redirect=me':
              // HIDE RESET PASSWORD USING MAIL VIEW
              this.resetPasswordActive = false;

              // HIDE NEW PASSWORD VIEW
              this.newPasswordFormActive = false; //New Password Form

              // RESET USER PASSWORD VARIABLE
              this.user.password = '';

              // REDIRECT USER USING LOCAL STORAGE DATA
              this.redirectUsingLocalStorage();

              break;
            default:
              // HIDE RESET PASSWORD USING MAIL VIEW
              this.resetPasswordActive = false;

              // HIDE NEW PASSWORD VIEW
              this.newPasswordFormActive = false;

              // RESET USER PASSWORD VARIABLE
              this.user.password = '';

              // SHOW EMAIL VALIDATION FORM
              this.emailValidationActive = true;

              // SHOW INFO MESSAGE ABOUT EMAIL CODE VERIFICATION
              this.systemMessageService.selectRibbon(
                'info',
                'emailValidationForCognito'
              );
              break;
          }
        } else {
          // SHOW LOGIN FORM
          this.loginActive = true;

          //Display error message on login form: Something went wrong, please try again.
          this.loginGenericErrorMessage = true;
        }
      } catch (error) {
        // SHOW LOGIN FORM
        this.loginActive = true;

        // Display error message on login form: Something went wrong, please try again.
        this.loginGenericErrorMessage = true;

        console.error('Error changing password:', error);
      }
    }
  }

  // Function called to validate the user email address in cognito
  async emailValidate() {
    // Call the function in cognito service to validate the email address
    await this.cognitoService.validateEmail(this.confirmationCode);
    // Cognito return a success message
    if (this.cognitoService.emailValidationResult.toUpperCase() === 'SUCCESS') {
      // Activate the login form and deactivate the email validation form
      // to do change username to email
      this.user.email = this.cognitoService.tempEmail;
      this.loginActive = true;
      this.emailValidationActive = false;
      // Throw a success message to the user
      this.systemMessageService.selectRibbon(
        'success',
        'validationEmailSuccess'
      );
    } else {
      // Throw an error message to the user
      this.systemMessageService.selectRibbon(
        'danger',
        'alert-danger-generic-message'
      );
    }
  }

  // Function called to validate the user phone number in cognito
  async phoneNumberValidate() {
    // // Call the function in cognito service to validate thephone number
    await this.cognitoService.validatePhoneNumber(this.confirmationCode);
    // // Cognito return a success message
    if (
      this.cognitoService.phoneNumberValidationResult.toUpperCase() ===
      'SUCCESS'
    ) {
      //   // Deactivate the phone validation form and redirect the user to the user-edit component
      this.phoneAuthentificationActive = false;
      this.router.navigate(['/user-edit']);
      //   // Throw a success message to the user
      this.systemMessageService.selectRibbon(
        'success',
        'alert-success-generic-message'
      );
    } else {
      //   // Throw an error message to the user
      this.systemMessageService.selectRibbon(
        'danger',
        'alert-danger-generic-message'
      );
    }
  }

  // Validate and display password strength
  validatePassword(): void {
    const password = this.requestNewPassword;
    const password2 = this.user.password;

    // Reset flags and checkboxes
    this.showAlertStrong = false;
    this.showAlertMedium = false;
    this.showAlertWeak = false;
    this.showCheckBoxTip = false;

    // Check password from new password view against strong and medium regex patterns
    if (this.validate.strongPassword.test(password)) {
      this.showAlertStrong = true;
      this.showCheckBoxTip = false;
    } else if (this.validate.strongPassword.test(password)) {
      this.showAlertMedium = true;
      this.showCheckBoxTip = true;
    } else if (password.length >= 1) {
      this.showAlertWeak = true;
      this.showCheckBoxTip = true;
    }

    // Check password from new password with reset code view against strong and medium regex patterns
    if (this.validate.strongPassword.test(password2)) {
      //Hide error message on login form: Something went wrong, please try again.
      this.loginGenericErrorMessage = false;
    }

    // Update checkbox and flag values based on password from new password view characteristics
    this.charactersCheckmodel = password.length >= 8;
    this.charactersChecked = this.charactersCheckmodel;

    this.lowerCaseCheckModel = this.hasLowerCase(password);
    this.lowerCaseChecked = this.lowerCaseCheckModel;

    this.upperCaseCheckModel = this.hasUpperCase(password);
    this.upperCaseChecked = this.upperCaseCheckModel;

    this.digitCheckModel = this.hasDigit(password);
    this.oneDigitChecked = this.digitCheckModel;

    this.specialCharCheckModel = this.hasSpecialChar(password);
    this.specialCharChecked = this.specialCharCheckModel;

    if (this.forgotPasswordForm) {
      // Update checkbox and flag values for password2 if the forgotPasswordForm is present
      this.charactersCheckmodel = password2.length >= 8;
      this.charactersChecked = this.charactersCheckmodel;

      this.lowerCaseCheckModel = this.hasLowerCase(password2);
      this.lowerCaseChecked = this.lowerCaseCheckModel;

      this.upperCaseCheckModel = this.hasUpperCase(password2);
      this.upperCaseChecked = this.upperCaseCheckModel;

      this.digitCheckModel = this.hasDigit(password2);
      this.oneDigitChecked = this.digitCheckModel;

      this.specialCharCheckModel = this.hasSpecialChar(password2);
      this.specialCharChecked = this.specialCharCheckModel;
    }
  }

  // Check email validity
  checkEmail() {
    //Set user object property
    this.user.email = this.email;

    // Regular expression to validate email format
    var validation = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');

    // Set userdigit flag to true
    this.userdigit = true;

    // Check if email is empty
    this.isEmptyEmail();

    // Check if userdigit is true and email format is not valid
    if (this.userdigit && !validation.test(this.user.email)) {
      this.invalidEmail = true;
      this.emailExist = true;
    } else this.invalidEmail = false; // Reset invalidEmail flag if email format is valid

    // Reset invalidEmail flag if email is empty
    if (this.emptyEmail) {
      this.invalidEmail = false;
    }
  }

  /**
   * Check if a string contains at least one lowercase character.
   * @param str The input string.
   * @returns True if the input string contains a lowercase character, otherwise false.
   */
  hasLowerCase(str: string) {
    return /[a-z]/.test(str);
  }

  /**
   * Check if a string contains at least one uppercase character.
   * @param str The input string.
   * @returns True if the input string contains an uppercase character, otherwise false.
   */
  hasUpperCase(str: string) {
    return /[A-Z]/.test(str);
  }

  /**
   * Check if a string contains at least one digit.
   * @param str The input string.
   * @returns True if the input string contains a digit, otherwise false.
   */
  hasDigit(str: string) {
    return /\d/.test(str);
  }

  /**
   * Check if a string contains at least one special character.
   * Special characters include !@#$%^&*(),.?":{}|<>
   * @param str The input string.
   * @returns True if the input string contains a special character, otherwise false.
   */
  hasSpecialChar(str: string) {
    return /[!@#$%^&*(),.?":{}|<>]/.test(str);
  }

  // Toggle the visibility of the password input's content.
  togglePasswordVisibility(from: string) {
    if (from === 'password') {
      let eyeIcon = document.getElementById('eyeIconPassword');
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        eyeIcon?.classList.remove('fa-eye-slash');
        eyeIcon?.classList.add('fa-eye');
      } else {
        eyeIcon?.classList.add('fa-eye-slash');
        eyeIcon?.classList.remove('fa-eye');
      }
    }
    if (from === 'confirmPassword') {
      let eyeIcon = document.getElementById('eyeIconConfirmPassword');
      this.showPasswordConfirmation = !this.showPasswordConfirmation;
      if (this.showPasswordConfirmation) {
        eyeIcon?.classList.remove('fa-eye-slash');
        eyeIcon?.classList.add('fa-eye');
      } else {
        eyeIcon?.classList.add('fa-eye-slash');
        eyeIcon?.classList.remove('fa-eye');
      }
    }
  }

  cancelHandler() {
    if (this.returnUrl === '' || this.returnUrl === null) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/' + this.returnUrl]);
    }
  }

  // Function called with the submit button in the sign-up MFA window
  submitMFACodeClick() {
    // this.mfaChecked is the variable that UI use to know if the user want the SMS_MFA or TOTP_MFA then go to the proper function
    if (this.mfaChecked) {
      //   // Call the function that will sign-up with SMS MFA code
      this.cognitoService.signUpMFACodeSMS(this.MFACodeSMS);
    } else {
      //   // Call the function that will sign-up with TOTP MFA code
      this.cognitoService.singUpMFACodeTOTP(this.MFACodeTOTP);
    }
  }

  // Function called to sign-in when a user get MFA setting active
  async MFASignIn() {
    this.loading = true;
    this.nextTotpPage = false;
    this.loginActive = false;
    this.MFACodeRequiredActive = false;
    // Call the cognito service to sign-in
    await this.cognitoService
      .MFASignIn(this.user, this.MFACode)
      .then((response) => {
        setTimeout(() => {
          if (response !== 'no-MFA-sign-in') {
            //       // If return response from cognito is not an error the authentification have success so we get the cognito user
            this.cognitoService
              .getUser()
              .then((response) => {
                this.MFACodeRequiredActive = true;
                this.loading = false;
                if (response) {
                  //           // If we get a user set the local strorage for the user and client he's in
                  this.localStorageService.addItem(
                    'user',
                    response.attributes.given_name +
                      ' ' +
                      response.attributes.family_name
                  );
                  this.localStorageService.addItem(
                    'client',
                    response.attributes['custom:client_id']
                  );
                  if (this.localStorageService.getItem('user').length !== 0) {
                    this.loading = true;
                    this.MFACodeRequiredActive = false;
                    this.cognitoService.getKeys();

                    setTimeout(() => {
                      this.redirectUsingLocalStorage();
                    }, 4000);
                  }
                }
              })
              .catch((error) => {
                console.error('Error in getting user : ', error);
                this.loading = false;
                this.MFACodeRequiredActive = true;
              });
          } else {
            this.loading = false;
            this.MFACodeRequiredActive = true;
          }
        }, 1000);
      });
  }

  // Function called in the sign-up MFA window when user click on the send button to get the QR code from cognito
  getMFARegisterCodeTOTP() {
    this.cognitoService.getQrCode();
  }

  // Function called to go back to user-edit
  backToEditPage() {
    this.router.navigate(['/user-edit']);
  }
} //End of Component
