import { ApiService } from './api.service';
import { Injectable, ɵrestoreComponentResolutionQueue } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  Subject,
  subscribeOn,
  firstValueFrom,
  tap,
} from 'rxjs';
import { Amplify, Auth } from 'aws-amplify';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import * as AWS from 'aws-sdk';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom, from } from 'rxjs';
import * as qrcode from 'qrcode';
import { SystemMessageService } from './system-message.service';
// import { error } from 'jquery';
import { formatDate } from '@angular/common';

//User Object Declaration
export interface IUser {
  email: string;
  password: string;
  code: string;
  name: string;
}

export interface SYUSER {
  userid: string | null;      // Assuming 'userid' can be a number or null
  cognitoid: string;          // 'cognitoid' is a string
  ulog: number;               // 'ulog' is a number, 1 by default
  ulock: number;              // 'ulock' is a number, 0 by default
  lastlogindate: string;      // 'lastlogindate' is a string (date format)
  lastlogintime: string;      // 'lastlogintime' is a string (time format)
  sqltrace: number;           // 'sqltrace' is a number, 0 by default
  sectype: string;            // 'sectype' is a string, likely a single character
  firstname: string;          // 'firstname' is a string
  lastname: string;           // 'lastname' is a string
  email: string;              // 'email' is a string
  phone: string;              // 'phone' is a string
  sysuser: string;            // 'sysuser' is a string
  eid: string;                // 'eid' is a string
  langid: string;             // 'langid' is a string
  uadmin: string;             // 'uadmin' is a string (likely a char)
  utype: string;              // 'utype' is a string
  certif: string;             // 'certif' is a string
  upol: number;               // 'upol' is a number, 0 by default
  polacd: string;             // 'polacd' is a string
  polact: string;             // 'polact' is a string
  acknowledge: string;        // 'acknowledge' is a string (likely a char)
  location: string;           // 'location' is a string
  syadmin: string;            // 'syadmin' is a string, 'F' by default
}


export interface CognitoIdentityServiceProvider {
  listUsers(
    params: AWS.CognitoIdentityServiceProvider.Types.ListUsersRequest
  ): AWS.Request<
    AWS.CognitoIdentityServiceProvider.Types.ListUsersResponse,
    AWS.AWSError
  >;
}

export interface AlertPreference {
  label: string;
  value: number;
}

export interface CognitoUser {
  username: string;
  given_name: string;
  family_name: string;
  legal_name: string;
  email: string;
  phone_number: string;
  address: string;
  custom_user_type: string;
  created: string;
  modified: string;
  user_status: string;
  sub: string;
  enabled: string;
  syuser?: SYUSER;
}

export interface UpdatedAttributes {
  email: string;
  phone_number: string;
  given_name: string;
  family_name: string;
  middle_name: string;
  picture: string;
  address: string;
  role: string;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  cognitoUserData = {
    username: '',
    password: '',
    given_name: '',
    family_name: '',
    address: '',
    email: '',
    phone_number: '',
    custom_current_role: '',
    custom_user_type: '',
    custom_client_id: '',
    custom_role: '',
    custom_distributor_id: '',
    custom_operator_id: '',
    sub: '',
    client_name: '',
    enabled: '',
  };

  public tempEmail: string = '';
  // for general use
  private successMessage: any;
  private userSub: any;
  public userInfos: any;

  public allUserRolesDeleted: boolean = false;
  public clientUserUpdated: boolean = false;
  public temporarySub: any;
  public temporaryRole: any;
  public muirwoodUserCreated: boolean = false;
  public clientUserCreated: boolean = false;
  public distributorUserCreated: boolean = false;
  public roleUserArray: any[] = [];
  public cognitoUsersArray: CognitoUser[] = [];
  public temporaryArray: any[] = [];
  public clientLegalName: any;
  public distributorLegalname: any;
  public operatorLegalName: string = '';
  public roleArrayToLambda: any[] = [];
  public legalName: string = '';

  private authenticationSubject: BehaviorSubject<any>;
  public challengeName: string = '';
  public currentRole: string = '';

  // Variables used in MFA sing-in sign-up functions
  public imgUrl: any;
  public userName: string = '';
  public userMFAPreference: string = '';
  public qrCodeImgActive: boolean = false;
  public confirmMFAMessage: string = '';
  public challengeParamDestynation: string = '';
  public userMFA: any;

  // Fucntions that will trigger system message to the user
  private wrongMFACode = new Subject<void>();
  wrongMFACode$ = this.wrongMFACode.asObservable();
  private MFASignUpSuccess = new Subject<void>();
  MFASignUpSuccess$ = this.MFASignUpSuccess.asObservable();

  public userRolesForUpdate: any;
  public userAllowed: boolean = false;

  // Variables used to get the user type
  public userType: string = '';
  public userRoles: any[] = [];

  // Variables used to get the client or distributor or operator ID
  public clientId: string = '';
  public distributorId: string = '';
  public operatorId: string = '';

  // Array used to have all client and distributor info's
  public distributorsInfosArray: any;
  public clientsInfosArray: any;
  public operatorsInfosArray: any;

  private userObject: any;
  private oldPassword: string = '';
  public emailValidationResult: String = '';
  public phoneNumberValidationResult: string = '';

  public alertPreferencesArray: AlertPreference[] = [];
  public deviceUpdateEmail: number = 0;
  public deviceUpdateSms: number = 0;
  public workOrderUpdateEmail: number = 0;
  public workOrderUpdateSms: number = 0;
  public monthlyReportEmail: number = 0;
  public monthlyReportSms: number = 0;
  public alertPreferencesResult: any[] = [];
  public preferedLanguage: string = '';

  private array: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private systemMessageService: SystemMessageService,
    private api: ApiService
  ) {
    //Set configs for communicating with Cognito, Most of this will be changed when this app is hosted
    Amplify.configure({
      Auth: environment.cognito,
    });
    // Set up AWS configuration
    AWS.config.update({
      region: environment.cognito.region,
      sessionToken: sessionStorage.getItem('sessionToken') || '',
      accessKeyId: sessionStorage.getItem('accessKeyId') || '',
      secretAccessKey: sessionStorage.getItem('secretAccessKey') || '',
      credentials: {
        sessionToken: sessionStorage.getItem('sessionToken') || '',
        accessKeyId: sessionStorage.getItem('accessKeyId') || '',
        secretAccessKey: sessionStorage.getItem('secretAccessKey') || '',
      },
    });

    //Init the authenticationSubject
    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  async configure() {
    Amplify.configure({
      cognito: environment.cognito,
    });
    //Returns temporary credentials for authenticated users
    const credentials = await Auth.currentCredentials();
    AWS.config.update({
      region: environment.cognito.region,
      sessionToken: credentials.sessionToken,
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      credentials: {
        sessionToken: credentials.sessionToken,
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
      },
    });
  }

  //Fetch authenticated user's temporary credential and set Session variables
  public async getKeys(errorCode: string = '') {
    //Returns temporary credentials for authenticated users
    const credentials = await Auth.currentCredentials();

    //Set Session variables
    sessionStorage.setItem('accessKeyId', credentials.accessKeyId);
    sessionStorage.setItem('secretAccessKey', credentials.secretAccessKey);
    sessionStorage.setItem('sessionToken', credentials.sessionToken);
    sessionStorage.setItem('cognitoUserIdentityId', credentials.identityId);

    AWS.config.update({
      region: environment.cognito.region,
      sessionToken: credentials.sessionToken,
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      credentials: {
        sessionToken: credentials.sessionToken,
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
      },
    });

    //Return credentials in an Object for use (if necessary)
    return {
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
    };
  }

  // chat gpt suggestion
  async refreshTokenChatGpt() {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

      // Extract tokens from the current session
      const accessToken = currentSession.getAccessToken().getJwtToken();
      const idToken = currentSession.getIdToken().getJwtToken();
      const refreshToken = currentSession.getRefreshToken().getToken();

      // Optionally, you can store the tokens in your application state or local storage
      // localStorage.setItem('accessToken', accessToken);
      // localStorage.setItem('idToken', idToken);
      // localStorage.setItem('refreshToken', refreshToken);

      return { accessToken, idToken, refreshToken };
    } catch (error) {
      console.error('Error refreshing tokens:', error);
      throw error;
    }
  }

  // verify if will be used at nova login work flow
  async refreshToken() {
    try {
      //Refresh the session tokens if refresh token is still valid
      await Auth.currentAuthenticatedUser({ bypassCache: true });
    } catch (error) {
      this.checkForbidden(error);
      console.error('Error refreshing token:', error);
      // Handle the error, e.g., redirect to login or re-authenticate the user
      Auth.signOut()
        .then((res) => {
          this.router.navigate(['/login']);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  //Verify the Authenticated User Session. Force sign out when authentication expired.
  async verifySession() {
    //Are session keys set?
    if (
      sessionStorage.getItem('sessionToken') === undefined ||
      sessionStorage.getItem('accessKeyId') === undefined ||
      sessionStorage.getItem('secretAccessKey') === undefined
    ) {
      //Get and set keys is session variables have not been set.
      await this.getKeys();
      //console.log('trying session refresh again')
      window.location.reload();
    }

    try {
      const session = await Auth.currentSession();
      const expirationTime = session.getIdToken().getExpiration();

      // Check if the token is expired or close to expiration
      const currentTime = Math.floor(Date.now() / 1000);

      const timeUntilExpiration = expirationTime - currentTime;

      if (timeUntilExpiration < 6000) {
        // Token is about to expire, initiate refresh
        await this.refreshToken();
        const credentials = await Auth.currentCredentials();

        //Set Session variables
        sessionStorage.setItem('accessKeyId', credentials.accessKeyId);
        sessionStorage.setItem('secretAccessKey', credentials.secretAccessKey);
        sessionStorage.setItem('sessionToken', credentials.sessionToken);
        sessionStorage.setItem('cognitoUserIdentityId', credentials.identityId);
        // Set up AWS configuration
        AWS.config.update({
          region: environment.cognito.region,
          sessionToken: credentials.sessionToken,
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          credentials: {
            sessionToken: credentials.sessionToken,
            accessKeyId: credentials.accessKeyId,
            secretAccessKey: credentials.secretAccessKey,
          },
        });

        if (window.location.pathname === '/login') {
          Auth.signOut()
            .then((res) => {
              this.router.navigate(['/login']);
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          //reload page to apply restored session
          window.location.reload();
        }
      }
    } catch (error) {
      //   this.checkForbidden(error);
      //If an error occurs, force signOut()
      Auth.signOut()
        .then((res) => {})
        .catch((error) => {
          console.error(error);
          this.router.navigate(['/login']);
        });
    }
  }


  public getUserByUserid(userid: string | null, users: SYUSER[]): SYUSER | undefined {
    if (!Array.isArray(users)) {
      console.error('users is not an array');
      return undefined;
    }

    // Check if users array is valid and find the user with the matching userid
    return users.find(user => user.userid === userid);
  }

  //Returns all Cognito Users (no lazy loading)
  public async getAllUsers() {
    await this.configure();
    // Clear cognitoUsersArray to store user data
    this.cognitoUsersArray.splice(0, this.cognitoUsersArray.length);
    this.temporaryArray = [] as any[];
    let filter: string = '';

    // Create an instance of the CognitoIdentityServiceProvider using AWS SDK
    const cognitoIdentityServiceProvider: CognitoIdentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider();

    const params: AWS.CognitoIdentityServiceProvider.Types.ListUsersRequest = {
      UserPoolId: environment.cognito.userPoolId,
    };

    try {
      const syusers: SYUSER[] = await this.api.getSYUSERS().then((res)=>{
        return res;
      });
      console.log(syusers)
      // Initialize the PaginationToken as null
      let paginationToken: string | undefined = undefined;

      // Loop through pages of users until the last page is reached
      do {
        // If paginationToken is available, set it in the request params to get the next page of results
        if (paginationToken) {
          params.PaginationToken = paginationToken;
        }

        // Fetch the list of users
        const data = await cognitoIdentityServiceProvider
          .listUsers(params)
          .promise();

        // Store the user data in a temporary array for processing
        this.temporaryArray.push(data.Users);

        // If there is a PaginationToken in the response, set it for the next iteration
        paginationToken = data.PaginationToken;
        if (data.Users) {
          // Iterate through the list of users and process them
          for (let i = 0; i < data.Users.length; i++) {
            // Initialize variables for legal names
            this.clientLegalName = '';
            this.distributorLegalname = '';

            // Access the current user item in the data array
            const item = data.Users[i];

            // Helper function to find attribute values by name
            const findAttribute = (name: string) => {
              if (item.Attributes) {
                const attribute = item.Attributes.find(
                  (attr: any) => attr.Name === name
                );
                return attribute ? attribute.Value : '';
              }
              return '';
            };

            // Extract attributes for common user data
            const sub = findAttribute('sub') || '';

            let id = '';
            let legalNameValue = '';



            const syuser: SYUSER | undefined = this.getUserByUserid(item.Username || "", syusers);
           // const syuser = [];
            // Create a CognitoUser object and add it to the array
            const cognitoUser: CognitoUser = {
              username: item.Username || '',
              given_name: findAttribute('given_name') || '',
              family_name: findAttribute('family_name') || '',
              email: findAttribute('email') || '',
              phone_number: findAttribute('phone_number') || '',
              address: findAttribute('address') || '',
              custom_user_type:
                findAttribute('custom:user_type')?.toUpperCase() || '',
              legal_name: legalNameValue,
              created: item.UserCreateDate?.toString() || '',
              modified: item.UserLastModifiedDate?.toString() || '',
              user_status: item.UserStatus || '',
              sub: sub,
              enabled: findAttribute('custom:enable') || '',
              syuser: syuser
            };

            // Push the new user to the cognitoUsersArray
            this.cognitoUsersArray.push(cognitoUser);
          }
        }
      } while (paginationToken); // Continue fetching until there are no more pages

      // Return the array of CognitoUser objects
      return this.cognitoUsersArray;
    } catch (error) {
      // Handle errors during user retrieval
      this.checkForbidden(error);
      console.error('Error getting user list:', error);
      this.verifySession();
      throw error;
    }
  }

  //Check if a user is logged in
  public async confirmValidUser() {
    try {
      await Auth.currentCredentials();
      await Auth.currentAuthenticatedUser();
      //Attempt to fetch active user information
      if (await this.getUser()) {
        //Verify the authenticated user session. Force SignOut if expired.
        this.verifySession();

        return true;
      } else {
        //Force Signout if getUser fails
        this.signOut()
          .then((response) => {
            this.router.navigate(['/login']);
          })
          .catch((error) => {
            console.error(error);
            this.router.navigate(['/login']);
          });
        //If no active user, sends the user back to /login

        return false;
      }
    } catch (error) {
      //In the event of an error, default to /login page and force SignOut
      this.checkForbidden(error);
      console.error('Error: ', error);
      this.signOut()
        .then((response) => {
          this.router.navigate(['/login']);
        })
        .catch((error) => {
          console.error(error);
          this.router.navigate(['/login']);
        });
      return false;
    }
  }

  //Returns active user information. This is how we get the User ID
  public async getUser(): Promise<any> {
    // Set a variable of user infos to avoid multiple calls
    this.userInfos = await Auth.currentUserInfo();
    return this.userInfos;
  }

  //Get Authenticated user credentials. Return Promise
  public async getCredentials(): Promise<any> {
    return await Auth.currentCredentials();
  }

  //Get User by ID
  getUserNameBySub(sub: string): string {
    const user = this.cognitoUsersArray.find((u) => u.sub === sub);

    // Verifica se encontrou o usuário
    if (user) {
      // Retorna o nome do usuário (pode ser ajustado conforme a estrutura do seu objeto de usuário)
      return `${user.given_name} ${user.family_name}`;
    } else {
      // Retorna uma string padrão ou outra indicação de que o usuário não foi encontrado
      return 'Usuário não encontrado';
    }
  }

  //Formats string by adding quotes.
  addQuotes(str: string): string {
    return `'${str}'`;
  }

  // Function called to clear cognitoUserData
  public clearCognitoUserData() {
    this.cognitoUserData = {
      username: '',
      password: '',
      given_name: '',
      family_name: '',
      address: '',
      email: '',
      phone_number: '',
      custom_current_role: '',
      custom_user_type: '',
      custom_client_id: '',
      custom_role: '',
      custom_distributor_id: '',
      custom_operator_id: '',
      sub: '',
      client_name: '',
      enabled: '',
    };
  }

  //Validates the current user's role attributes against the expected role attributes for a given component or element
  public async getCurrentRole(
    validRole: string[],
    validUserType: string[],
    redirect: boolean = true,
    userRoles: string[] = []
  ) {
    try {
      let result: any;
      //Get the actual user whit Cognito AWS
      if (this.userInfos === '' || this.userInfos === undefined) {
        result = await this.getUser();
      } else {
        result = this.userInfos;
      }

      let currentRoles: any;
      let roleOK: boolean = false;

      //Assign result of Cognito actual user to currentRole & currentUserType variable
      if (this.userRoles.length !== 0 && this.userRoles !== undefined) {
        // Check if the role number are in validRole
        if (this.userRoles.some((role: any) => validRole.includes(role))) {
          roleOK = true;
        }
      } else {
        currentRoles = result.attributes['custom:current_role'];
        if (validRole.includes(currentRoles)) {
          roleOK = true;
        }
      }

      const currentUserType = result.attributes['custom:user_type'];

      // Check if the current role matches the valid role
      if (roleOK && validUserType.includes(currentUserType)) {
        this.userAllowed = true; // Role is valid
      } else {
        // If roles don't match and redirect is true, navigate to the dashboard
        if (redirect) {
          this.router.navigate(['/dashboard']);
        }

        // Role is not valid
        this.userAllowed = false;
      }
    } catch (error) {
      // Handle errors during user retrieval
      this.checkForbidden(error);
      console.error('Error getting user: ', error);
      // Sign out the user and navigate to the login page
      this.signOut().then((response) => {
        this.router.navigate(['/login']);
      });
      this.userAllowed = false; // Role check failed due to error
    }
  }

  //Check if user was logged in successfully and is active
  public async isAuthenticated(): Promise<boolean> {
    //Check Authentication Subject Object. This is set in the signIn() function
    if (this.authenticationSubject.value) {
      //Return the Promise to confirm success
      return Promise.resolve(true);
    } else {
      //If the Authentication Subject Object is false, verify authentication with AWS
      return await this.getUser()
        .then((user: any) => {
          //Verify that an active user object was returned
          if (user) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          this.checkForbidden(error);
          //log errors and return false to trigger conditional logic in the LoginComponent
          console.error('Error:', error);
          return false;
        });
    }
  }

  //Used for debugging sessions. Only logs session information and errors
  public async currentSession(): Promise<any> {
    // Retrieve the current session
    await Auth.currentSession()
      .then((currentSession: any) => {
        // Extract tokens from the resolved session object
        const idToken = currentSession.getIdToken().getJwtToken();
        const accessToken = currentSession.getAccessToken().getJwtToken();
        const refreshToken = currentSession.getRefreshToken().getToken();
      })
      .catch((error) => {
        this.checkForbidden(error);
        //Log errors
        console.error('Error getting session:', error);
        this.verifySession();
      });
  }

  //Sends an email to the user containing a passwor reset code
  public async forgotPassword(username: string): Promise<any> {
    try {
      //Request to AWS via Amplify to send a user a reset code by email or sms
      const forgotPassword = await Auth.forgotPassword(username);
      return forgotPassword;
    } catch (error) {
      this.checkForbidden(error);
      console.error('Something went wrong: ', error);
      return false;
    }
  }

  // Function to generate a random temporary password with specific criteria
  generateTemporaryPassword() {
    const length = 12; // Set the desired password length
    const charset =
      '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=<>?';
    const specialChars = '!@#$%^&*()_-+=<>?';

    let temporaryPassword = '';

    // Ensure inclusion of at least one number
    const randomDigit = Math.floor(Math.random() * 10); // Generate a random number from 0 to 9
    temporaryPassword += charset[randomDigit];

    // Ensure inclusion of at least one special character
    const randomSpecialChar = Math.floor(Math.random() * specialChars.length);
    temporaryPassword += specialChars[randomSpecialChar];

    // Generate the remaining characters of the password
    for (let i = 2; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      temporaryPassword += charset[randomIndex];
    }

    return temporaryPassword; // Return the generated temporary password
  }

  toggleRole(role: any) {
    // Check if the role is already in the array of user roles
    if (this.roleUserArray.includes(role)) {
      // The role is already in the array, so remove it
      this.roleUserArray = this.roleUserArray.filter((r) => r !== role);
    } else {
      // The role is not in the array, so add it
      this.roleUserArray.push(role);
    }
  }

  // Function called to disable user access in cognito
  async disableUserAccess(username: string): Promise<string> {
    await this.configure();
    // Initialize Cognito Identity Service Provider with the appropriate region
    const cognitoIdentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider({
        region: environment.cognito.region,
      });

    // Set parameters for the user deletion request
    const params = {
      UserPoolId: environment.cognito.userPoolId,
      Username: username,
    };

    // Create a promise to handle the asynchronous operation
    return new Promise(async (resolve, reject) => {
      // Make an adminDeleteUser request to delete the specified user
      cognitoIdentityServiceProvider.adminDisableUser(
        params,
        (err: any, data: any) => {
          if (err) {
            // Log an error message if deletion fails
            console.error('Error disable user:', err);
            // Reject the promise with the error
            reject(err);
          } else {
            // Initialize Cognito Identity Service Provider with the appropriate region
            const cognitoIdentityServiceProvider2 =
              new AWS.CognitoIdentityServiceProvider({
                region: environment.cognito.region,
              });
            // If we are able to disable user in cognito we need to set his attribute enable to '0' so we know he's disabled
            const updateParams = {
              UserPoolId: environment.cognito.userPoolId,
              Username: username,
              UserAttributes: [
                {
                  Name: 'custom:enable',
                  Value: '0',
                },
              ],
            };
            try {
              // Update user attributes in Cognito User Pool
              const enableResultUpdate = cognitoIdentityServiceProvider2
                .adminUpdateUserAttributes(updateParams)
                .promise();
              // Resolve the promise if the user is deleted successfully
              resolve('success');
            } catch (error) {
              this.checkForbidden(error);
              console.error(error);
            }
          }
        }
      );
      this.cognitoUserData.enabled = '0';
    });
  }

  // Function called to enabled user access in cognito
  enableUserAcces(username: string): Promise<string> {
    // Initialize Cognito Identity Service Provider with the appropriate region
    const cognitoIdentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider({
        region: environment.cognito.region,
      });

    // Set parameters for the user deletion request
    const params = {
      UserPoolId: environment.cognito.userPoolId,
      Username: username,
    };

    // Create a promise to handle the asynchronous operation
    return new Promise(async (resolve, reject) => {
      // Make an adminDeleteUser request to delete the specified user
      cognitoIdentityServiceProvider.adminEnableUser(
        params,
        (err: any, data: any) => {
          if (err) {
            // Log an error message if deletion fails
            console.error('Error deleting user:', err);
            // Reject the promise with the error
            reject(err);
          } else {
            // Initialize Cognito Identity Service Provider with the appropriate region
            const cognitoIdentityServiceProvider2 =
              new AWS.CognitoIdentityServiceProvider({
                region: environment.cognito.region,
              });
            // If we are able to enable user in cognito we need to set his attribute enable to '1' so we know he's enabled
            const updateParams = {
              UserPoolId: environment.cognito.userPoolId,
              Username: username,
              UserAttributes: [
                {
                  Name: 'custom:enable',
                  Value: '1',
                },
              ],
            };
            try {
              // Update user attributes in Cognito User Pool
              const enableResultUpdate = cognitoIdentityServiceProvider2
                .adminUpdateUserAttributes(updateParams)
                .promise();
              resolve('success');
            } catch (error) {
              this.checkForbidden(error);
              console.error(error);
            }
            // Resolve the promise if the user is deleted successfully
          }
        }
      );
      this.cognitoUserData.enabled = '1';
    });
  }

  // Function called when we create a user. to create it in cognito then send email invitation
  // This function will create a user with is username and email, then update it to set his information and finally set him into a group
  async UserSignUpAndSendTemporaryPassword(
    userType: string,
    userCurrentRole: string
  ): Promise<any> {
    // Create an instance of CognitoIdentityServiceProvider using AWS SDK
    const cognitoIdentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider({
        region: environment.cognito.region,
      });

    // Extract user information from the form
    const username = this.cognitoUserData.username; // Get form username
    const temporaryPassword = this.generateTemporaryPassword(); // Generate a random temporary password
    const email = this.cognitoUserData.email; // Get form email address
    const phoneNumber = this.cognitoUserData.phone_number.replace(/\D/g, '');

    // Set custom attributes for client user
    this.cognitoUserData.custom_user_type = userType;
    this.cognitoUserData.custom_current_role = userCurrentRole;

    // Set parameters for creating a new user in Cognito User Pool
    const params = {
      UserPoolId: environment.cognito.userPoolId,
      Username: username,
      TemporaryPassword: temporaryPassword,
      UserAttributes: [
        {
          Name: 'email',
          Value: email,
        },
      ],
    };

    // Function called to check if there's already the user email in the cognito service
    const duplication = await firstValueFrom(
      from(this.checkForDuplicateEmailAddress())
    );
    if (duplication) {
      return 'duplicated';
    }
    // Choose one of the following options for MessageAction:
    // - 'SUPPRESS': Prevents an automatic welcome email from being sent.
    // - 'RESEND': Resends the welcome email.

    // Example usage:
    // MessageAction: 'SUPPRESS', // Use this option to suppress the welcome email
    // MessageAction: 'RESEND', // Use this option to resend the welcome email

    try {
      // Create a new user in Cognito User Pool
      const result = await cognitoIdentityServiceProvider
        .adminCreateUser(params)
        .promise();

      // Retrieve user details to get the 'sub'
      const userParams = {
        UserPoolId: environment.cognito.userPoolId,
        Username: username,
      };
      // Get bcck the instance of the user we just create
      await cognitoIdentityServiceProvider
        .adminGetUser(userParams)
        .promise()
        .then(async (user) => {
          if (user.UserAttributes) {
            // Call the function to set the params for the update of the user
            const paramsUpdateAttributes =
              this.getUpdateAttributeParams(userType);

            // Update user attributes in Cognito User Pool
            const resultUpdateAttributes = await cognitoIdentityServiceProvider
              .adminUpdateUserAttributes(paramsUpdateAttributes)
              .promise();

            // The 'sub' of the user can be found in user.UserAttributes
            const sub = user.UserAttributes.find(
              (attr: any) => attr.Name === 'sub'
            )?.Value;

            // If 'sub' is available, send data to API function
            if (sub) {
              //TO DO
              this.userSub = sub;
            }
            // Function called to add the created user to a group
            this.addUserToGroup(username, userType);
            await this.createDBUser();
          } else {
            console.error('User attributes are undefined.');
            // Handle the case where user.UserAttributes is undefined, such as throwing an error or returning early.
          }
        });

      // Return the result
      return result;
    } catch (error) {
      // Set clientUserCreated flag to false and log the error
      this.checkForbidden(error);
      this.clientUserCreated = false;
      console.error(error);
      return Promise.reject(error);
    }
  }

  // Function called to check in cognito if the entered email adress of the new user is already used
  async checkForDuplicateEmailAddress(): Promise<boolean> {
    if (this.cognitoUsersArray.length === 0) {
      this.getAllUsers();
    }
    let duplicated = false;

    for (const user of this.cognitoUsersArray) {
      if (this.cognitoUserData.email === user.email) {
        duplicated = true;
      }
    }
    return duplicated;
  }

  // function called by UserSignUpAndSendTemporaryPassword to get the proper param to update the cognito user
  getUpdateAttributeParams(userType: string) {
    // Initialize a variable for the specific Id that we need to create this user
    let name = '';
    let value = '';
    let phoneNumber = this.cognitoUserData.phone_number.replace(/\D/g, '');
    const username = this.cognitoUserData.username; // Get form username

    // Check from the received user type witch is the good attribute to send
    //TO ADD

    if (phoneNumber.length > 0) {
      phoneNumber = '+1' + phoneNumber;
    }

    // Set parameters for updating user attributes
    const paramsUpdateAttributes = {
      UserPoolId: environment.cognito.userPoolId,
      Username: username,
      UserAttributes: [
        {
          Name: 'address',
          Value: this.cognitoUserData.address,
        },
        {
          Name: 'family_name',
          Value: this.cognitoUserData.family_name,
        },
        {
          Name: 'given_name',
          Value: this.cognitoUserData.given_name,
        },
        {
          Name: 'phone_number',
          Value: phoneNumber,
        },
        {
          Name: 'custom:user_type',
          Value: this.cognitoUserData.custom_user_type,
        },
        {
          Name: 'custom:current_role',
          Value: this.cognitoUserData.custom_current_role,
        },
        {
          Name: 'custom:enable',
          Value: '1',
        },
        {
          Name: name,
          Value: value,
        },
      ],
    };
    return paramsUpdateAttributes;
  }

  // Function used when you update an user with the user-update pages
  async updateUserAttributesCognito(username: any) {
    // Remove non-numeric characters from the phone number
    const numericOnly = this.cognitoUserData.phone_number.replace(/\D/g, '');
    let formattedNumber = '';
    if (numericOnly.length > 10) {
      formattedNumber = `+${numericOnly}`;
    }

    if (numericOnly.length == 10) {
      formattedNumber = `+1${numericOnly}`;
    }

    this.cognitoUserData.phone_number = formattedNumber;

    // Extract phone number and create an instance of CognitoIdentityServiceProvider using AWS SDK
    const cognitoIdentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider({
        region: environment.cognito.region,
      });

    // Define attributes to update for the MUIRWOOD user
    const attributesToUpdate = [
      {
        Name: 'given_name',
        Value: this.cognitoUserData.given_name,
      },
      {
        Name: 'family_name',
        Value: this.cognitoUserData.family_name,
      },
      {
        Name: 'phone_number',
        Value: formattedNumber,
      },
      {
        Name: 'address',
        Value: this.cognitoUserData.address,
      },
    ];

    // Set parameters for updating user attributes
    const params = {
      UserPoolId: environment.cognito.userPoolId,
      Username: username,
      UserAttributes: attributesToUpdate,
    };

    try {
      await this.updateDBUser();
      // Update user attributes in Cognito User Pool
      const result = await cognitoIdentityServiceProvider
        .adminUpdateUserAttributes(params)
        .promise();

      return result;
    } catch (error) {
      // Log an error message if the update fails
      this.checkForbidden(error);
      console.error('User attributes update failed:', error);
      return null;
    }
  }

  public validateAndUpdatePreference(value: number): number {
    // Check if the value is a number and is between 0 and 1
    if (typeof value === 'number' && (value === 0 || value === 1)) {
      return value; // If correct, return the original value
    } else {
      return 0; // Otherwise, return 0
    }
  }

  // Function called to transfert JSON string for integer when needed
  switchJSONToIntForUserPreferenceResponse(element: any) {
    // Table of witch keys we need to check
    const keysToCheck = [
      'device_update_email',
      'device_update_sms',
      'workorder_update_email',
      'workorder_update_sms',
      'monthly_report_email',
      'monthly_report_sms',
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  switchJSONToIntForClientResponse(element: any) {
    // Table of witch keys we need to check
    const keysToCheck = ['created', 'modiifed'];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  keyToTransfert(keysToCheck: any, element: any) {
    keysToCheck.forEach((key: any) => {
      // Vérifier si la clé existe dans l'élément et si sa valeur est une chaîne
      if (typeof element[key] === 'string') {
        // Convertir la valeur de la clé en entier
        element[key] = parseInt(element[key], 10);
      }
    });
  }

  //Updates User attributes after authenticating the current user, returns boolean for error message handling used in user-edit page
  public async updateUserAttributes(
    updateUserAttributes: UpdatedAttributes
  ): Promise<any> {
    try {
      await this.updateDBUser();
      // Get the current user object
      const userObj = await Auth.currentAuthenticatedUser();
      // Try to do the update on the current user object and will return true
      const update = await Auth.updateUserAttributes(userObj, {
        email: updateUserAttributes.email,
        phone_number: updateUserAttributes.phone_number,
        given_name: updateUserAttributes.given_name,
        family_name: updateUserAttributes.family_name,
        middle_name: updateUserAttributes.middle_name,
        picture: updateUserAttributes.picture,
        address: updateUserAttributes.address,
        'custom:current_role': updateUserAttributes.role,
      });

      return true;
    } catch (error) {
      //Log errors
      this.checkForbidden(error);
      console.error('Error: ', error);
      return false;
    }
  }

  //Resets password as part of the forgot-password workflow. requires a reset code emailed to the user via forgotPassword()
  public async resetPassword(
    username: string,
    code: string,
    newPassword: string
  ): Promise<any> {
    try {
      const passwordReset = Auth.forgotPasswordSubmit(
        username,
        code,
        newPassword
      );
      return passwordReset;
    } catch (error) {
      this.checkForbidden(error);
      console.error('Error: ', error);
      return false;
    }
  }

  public async signIn(user: IUser): Promise<any> {
    try {
      console.log(user);
      //Sign In with AWS Cognito using AWS Amplify
      const userObject = await Auth.signIn(user.email, user.password, {
        // Specify the token scopes you need
        scopes: 'openid email profile aws.cognito.signin.user.admin',
      }).then((user) => {
        //console.log(user)
        return user;
      });
      this.userName = userObject.username;
      // Set is own property to be abble to reconnect whit the userObject at the first signIn of the user in changePassword function
      this.userObject = userObject;

      //If Sign In was successful, check the response from AWS
      if (userObject) {
        switch (userObject.challengeName) {
          case 'NEW_PASSWORD_REQUIRED':
            //Handle first time sign-in with temporary passwords
            //Set global challengeName(AWS Response message)
            this.challengeName = userObject.challengeName;
            this.oldPassword = user.password;

            //Return string for conditional logic
            return 'change-password';
            break;

          case 'SMS_MFA':
          case 'SOFTWARE_TOKEN_MFA':
            this.challengeName = userObject.challengeName;
            return 'MFA-required';
            break;

          default:
            //If the Sign-In response was standard, try to fetch the current session
            try {
              const currentSession = await Auth.currentSession().then(
                (session) => {
                  this.authenticationSubject.next(true); //set authenticationSubject
                  // Use the user session to get temporary AWS credentials
                  this.getKeys();
                }
              );

              return userObject; // Sign-in successful
            } catch (error) {
              //Log Session Error
              this.checkForbidden(error);
              console.error('Error getting session:', error);
              return false;
            }
            break;
        }
      } else {
        this.authenticationSubject.next(false); //set authenticationSubject
        return false;
      }
    } catch (error) {
      this.checkForbidden(error);
      //Log Sign In Error
      console.error('Error signing in:', error);
      this.authenticationSubject.next(false); //set authenticationSubject
      return false; // Sign-in failed
    }
  }

  //Signout to end Active Session
  public async signOut(): Promise<any> {
    return await Auth.signOut().then(() => {
      //Update authenticationSubject to prevent navigation without an active user
      this.authenticationSubject.next(false);
    });
  }

  //Handles all password changes functionality
  public async changePassword(user: IUser, newPassword: string): Promise<any> {
    try {
      //Handle first-time login
      if (this.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const newPasswordResult = await Auth.completeNewPassword(
          this.userObject,
          newPassword
        )
          .then(async (result) => {
            if (result) {
              // Set the instance of the user
              const user = await Auth.currentAuthenticatedUser();
              // Send a SMS to the phone number of the user that is provide by cognito
              await Auth.verifyUserAttribute(user, 'email');
            }
          })
          .catch((error) => {
            // this.checkForbidden(error);
            console.error('Error: ', error);
          });
      } else {
        //Standard password change for an active and authenticated user
        // Get the currently authenticated user
        await Auth.currentAuthenticatedUser()
          .then((userObj) => {
            // Change user password
            Auth.changePassword(userObj, user.password, newPassword)
              .then(() => {
                return true;
              })
              .catch((error) => {
                // this.checkForbidden(error);
                console.error('Error : ', error);
                return false;
              });
          })
          .catch((error) => {
            // this.checkForbidden(error);
            console.error('Error : ', error);
            return false;
          });
      }

      //Empty challengeName to stabilize conditional logic for AWS responses
      this.challengeName = '';

      return true;
    } catch (error) {
      //Log Error and return false to handle errors in the relevant component
      this.checkForbidden(error);
      console.error('Error in cognito service:', error);
      return false;
    }
  }

  // Function that verify the code send by email to the user
  async validateEmail(confirmationCode: string) {
    // Set the instance of the current user
    const user = await Auth.currentAuthenticatedUser();
    this.tempEmail = user.attributes.email;
    // Call the function that check the code provide by cognito and send to the user by email
    await Auth.verifyUserAttributeSubmit(user, 'email', confirmationCode)
      .then((result) => {
        this.emailValidationResult = result;
      })
      .catch((error) => {
        this.checkForbidden(error);
        console.error(error);
      });
  }

  // Function that verify the code send by phone number to the user
  async validatePhoneNumber(confirmationCode: string) {
    // Set the instance of the current user
    const user = await Auth.currentAuthenticatedUser();
    // Call the function that check the code provide by cognito and send to the user by phone number
    await Auth.verifyUserAttributeSubmit(user, 'phone_number', confirmationCode)
      .then((result) => {
        this.phoneNumberValidationResult = result;
      })
      .catch((error) => {
        this.checkForbidden(error);
        console.error(error);
      });
  }

  // Function called to sign-in when MFA is activate on cognito user
  async MFASignIn(user: IUser, MFACode: string) {
    try {
      //Sign In with AWS Cognito using AWS Amplify
      const userObject = await Auth.signIn(user.email, user.password, {
        // Specify the token scopes you need
        scopes: 'openid email profile aws.cognito.signin.user.admin',
      });
      // Will make the confirm sign-in if SMS MFA is active for the user
      if (this.challengeName === 'SMS_MFA') {
        // Await confirmation from cognito if user enter the good MFA code
        const newUser = await Auth.confirmSignIn(
          userObject,
          MFACode,
          'SMS_MFA'
        ).then((result) => {});
      }
      // Will make the confirm sign-in if TOTP MFA is active for the user
      if (this.challengeName === 'SOFTWARE_TOKEN_MFA') {
        // Await confirmation from cognito if user enter the good MFA code
        await Auth.confirmSignIn(userObject, MFACode, 'SOFTWARE_TOKEN_MFA')
          .then((confirmation) => {
            // Return the confirmation if so
            return confirmation;
          })
          .catch((error) => {
            this.wrongMFACode.next();
            console.error(error);
            return 'no-MFA-sign-in';
          });
      }
      return '';
    } catch (error: any) {
      this.checkForbidden(error);
      console.error('Error on verification of MFA code :', error);
      return 'no-MFA-sign-in';
    }
  }

  // Function called when user want to sign-up whit SMS MFA code /////////// Not in use for now since no SMS was send from cognito
  async signUpMFACodeSMS(codeMFA: string) {
    try {
      // Get current user authentification
      const user = await Auth.currentAuthenticatedUser();
      // Send to aws the MFA code that he receive in SMS and verified if it's the good code that was send to his phone number
      await Auth.verifyUserAttributeSubmit(user, 'phone_number', codeMFA);
      // If the verifyUserAttributeSubmit work it will automatically activate this user to be authentificated by MFA when he log-in
      await Auth.setPreferredMFA(user, 'SMS_MFA');
      // Trigger a function that will display a message t the user on success
      this.MFASignUpSuccess.next();
    } catch (error) {
      // trigger a function in lgin component to display a message ribon to the user
      this.wrongMFACode.next();
      this.checkForbidden(error);
      console.error('Error in the confirmation of the user: ' + error);
    }
  }

  // Function called  in the sign-up MFA window when user click submit and try to verified if the TOTP token work between his authenticate app and cognito
  async singUpMFACodeTOTP(MFACode: string) {
    try {
      // Get the current CognitoUser
      const user = await Auth.currentAuthenticatedUser();

      // Verify the TOTP token that user enter if cognito can see it
      await Auth.verifyTotpToken(user, MFACode)
        .then((confirmation) => {
          this.confirmMFAMessage = 'mfaSignUpTOTPSuccess';
          // If the verified token is good, confirmation will be success and we'll set the MFA preference of the user to SOFTWARE_TOKEN_MFA
          Auth.setPreferredMFA(user, 'SOFTWARE_TOKEN_MFA');
          // Redirect the user to the user-edit page
          // this.router.navigate(['/user-edit']);
          // Trigger a function that will display a message t the user on success
          this.MFASignUpSuccess.next();
        })
        .catch((error) => {
          this.checkForbidden(error);
          console.error(error);
          // Trigger a function in the login component to display a message ribon to the user
          this.wrongMFACode.next();
        });
    } catch (error) {
      this.checkForbidden(error);
      console.error(error);
    }
  }

  // Function that send a SMS to the user so he will be able to activate the SMS_MFA
  /////////////////// TODO: will need to be continued when we get access to SMS from cognito /////////////////////
  async sendSMS() {
    try {
      // Set the instance of the user
      const user = await Auth.currentAuthenticatedUser();
      // Send a SMS to the phone number of the user that is provide by cognito
      await Auth.verifyUserAttribute(user, 'phone_number');
    } catch (error) {
      this.checkForbidden(error);
      console.error('Error on sending SMS: ' + error);
    }
  }

  // Function that get a QR code
  async getQrCode() {
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        // Call the function to get a secret code from cognito and put it into qrCode
        const qrCode = await Auth.setupTOTP(user);
        // Set the url string to get a readable QR code from lib qrcode
        const qrCodeUrl =
          'otpauth://totp/' +
          user.username +
          '?secret=' +
          qrCode +
          // '&issuer=IFM&algorithm=SHA1&digits=6&period=30';
          '&issuer=NOVA&algorithm=SHA1&digits=6&period=30';

        // Call function toDataUrl from lib qrcode to get an imageUrl of the qr code readable for the user
        qrcode
          .toDataURL(qrCodeUrl)
          .then((imageUrl: any) => {
            this.qrCodeImgActive = true;
            // Set the result in a variable that will be used in HTML to display the Qr code to the user
            this.imgUrl = imageUrl;
          })
          .catch((error: any) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
        this.checkForbidden(error);
      });
  }

  // Get user MFA preference
  async getUserMFAPreference() {
    // Get the instence of the user
    await Auth.currentAuthenticatedUser().then((cognitoUser) => {
      // Get user MFA preference if this user is allready set
      Auth.getPreferredMFA(cognitoUser, { bypassCache: true })
        .then((result) => {
          this.userMFAPreference = result; // Set variable to the preference of MFA of the user
        })
        .catch((err) => {
          this.checkForbidden(err);
          console.error(err);
        });
    });
  }

  // Get the user type (custom attributes of cognito) to implement the bin create page whit the good selects
  async getUserType() {
    // Get the instance of the current user
    await Auth.currentAuthenticatedUser().then((userObject) => {
      // Set the user type variable that bin create and update will use
      this.userType = userObject.attributes['custom:user_type'];

      // Switch between user type to get the client or distributor ID and set it to is own variable
      switch (this.userType) {
        case 'muirwood':
          // set the clientId variable to the userObject attribute
          this.clientId = userObject.attributes['custom:client_id'];
          break;
        case 'distributor':
          // Set the distributorId variable to the userObject attribute
          this.distributorId = userObject.attributes['custom:distributor_id'];
          break;
        case 'client':
          // set the clientId variable to the userObject attribute
          this.clientId = userObject.attributes['custom:client_id'];
          break;
        case 'operator':
          // Set the operatorId variable to the userobject attrbute
          this.operatorId = userObject.attributes['custom:operator_id'];
          break;
      }
    });
  }

  // Adds User to Cognito User Group. Called during User creation.
  async addUserToGroup(username: string, groupName: string) {
    try {
      //Declare Cognito Identity Provider class
      const cognitoIdentityServiceProvider =
        new AWS.CognitoIdentityServiceProvider();

      //Set reqiored parameters for the API call
      const params = {
        UserPoolId: environment.cognito.userPoolId,
        Username: username,
        GroupName: groupName,
      };

      // Call adminAddUserToGroup API to add the user to the group
      await cognitoIdentityServiceProvider
        .adminAddUserToGroup(params)
        .promise();
    } catch (error) {
      //log error in console
      this.checkForbidden(error);
      console.error('Error adding user to group:', error);
    }
  }

  async federatedSignIn(
    ambiente: any,
    accessToken: string,
    expires: number,
    name: string,
    email: string
  ) {
    return Auth.federatedSignIn(
      //  "sts.windows.net/c0b50357-f018-4f60-bf08-47342010bf9b"
      ambiente,
      // 'sts.windows.net/c0b50357-f018-4f60-bf08-47342010bf9b/',
      {
        token: accessToken,
        expires_at: expires,
      },
      {
        name: name,
        email: email,
      }
    );
  }

  // Function called from getAllUser to get the proper filter of user by the id of the current user
  getFilterString(kind: string) {
    let id = '';

    switch (kind) {
      case 'distributor':
        id = 'custom:distributor_id="' + this.distributorId + '"';
        break;
      case 'client':
        id = 'custom:client_id="' + this.clientId + '"';
        break;
      case 'operations  ':
        id = 'custom:operator_id="' + this.operatorId + '"';
        break;
    }
    return id;
  }

  // Function called to set cognito user in data base
  async createDBUser() {
    // Call the function that call the lambda function
    this.createDBUserLambda().subscribe((response) => {
      this.successMessage = response; // Return response from lambda and put it in success message so bin-list or bin-model-list and will be able to read it
      if (this.successMessage.message === 'success') {
        this.systemMessageService.selectRibbon(
          'success',
          'alert-success-generic-message'
        );
      } else {
        this.systemMessageService.selectRibbon(
          'danger',
          'alert-danger-generic-message'
        );
      }
    });
  }

  // Function called to update cognito user in data base
  async updateDBUser() {
    // Call the function that made the call to lambda function and wait for is response
    this.updateDBUserLambda().subscribe((response) => {
      this.successMessage = response;
      if (this.successMessage.message === 'success') {
        this.systemMessageService.selectRibbon(
          'success',
          'alert-success-generic-message'
        );
      } else {
        this.systemMessageService.selectRibbon(
          'danger',
          'alert-danger-generic-message'
        );
      }
    });
  }

  // Function that call lambda to create user in data base
  createDBUserLambda() {
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'yyyyMMdd', 'en_US');
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Adjust content type as needed
    });
    // Will call the lambda function in createBin url whit the passed data then return a response
    return this.http.post(
      environment.api.stage + environment.api.route.createUser,
      {
        // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        user: this.userSub,
        username: this.cognitoUserData.username,
        given_name: this.cognitoUserData.given_name,
        family_name: this.cognitoUserData.family_name,
        phone_number: this.cognitoUserData.phone_number,
        email: this.cognitoUserData.email,
        address: this.cognitoUserData.address,
        user_type: this.cognitoUserData.custom_user_type,
        current_role: this.cognitoUserData.custom_current_role,
        client_id: this.cognitoUserData.custom_client_id,
        distributor_id: this.cognitoUserData.custom_distributor_id,
        operator_id: this.cognitoUserData.custom_operator_id,
        enable: this.cognitoUserData.enabled,
        created: formattedDate,
        modified: formattedDate,
      },
      { headers: headers }
    );
  }

  // Function that call lambda to update user in data base
  updateDBUserLambda() {
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'yyyyMMdd', 'en_US');

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Adjust content type as needed
    });
    // Call lambda fucntion whit the url of updateBin  and return the response
    return this.http.post(
      environment.api.stage + environment.api.route.updateUser,
      {
        // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        user: this.cognitoUserData.sub,
        given_name: this.cognitoUserData.given_name,
        family_name: this.cognitoUserData.family_name,
        phone_number: this.cognitoUserData.phone_number,
        address: this.cognitoUserData.address,
        current_role: this.cognitoUserData.custom_role,
        modified: formattedDate,
      },
      { headers: headers }
    );
  }

  async checkForbidden(error: any) {
    this.verifySession();
    // Your asynchronous operation that may result in a 403 error
    if (
      error.toString().includes('Forbidden') ||
      error.toString().includes('Expired')
    ) {
      // Handle 403 error here
      await Auth.signOut().then(() => {
        let theme = localStorage.getItem('theme') ?? 'light';
        theme = theme?.replaceAll(/["\\]/g, '') ?? 'light';
        // Clear the local storage
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem('theme', '"' + theme + '"' || 'light');
        if (this.router.url !== '/login') {
          this.router.navigate(['/login']);
        }
      });
    } else {
      this.configure();
      // Handle other errors
      console.error('An error occurred:', error);
    }
  }
}
