import { Component } from '@angular/core';
import { CognitoService, CognitoUser } from '../service/cognito.service';
import { ApiService } from '../service/api.service'
@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})
export class ManagementComponent {
  public users: CognitoUser[] = [];
  public visibleUsers: CognitoUser[] = [];
  public searchQuery: string = '';
  public sortField: keyof CognitoUser = 'username'; // Ensuring sortField is one of the keys of CognitoUser
  public sortOrder: 'up' | 'down' = 'up';
  private currentPage: number = 0; // Current page number for pagination
  private pageSize: number = 20; // Number of users to load per request
  public loading: boolean = false;

  // Property to hold the selected user
  public selectedUser: CognitoUser | null = null;

  // Properties for multiple selection
  public selectedUsers: CognitoUser[] = [];
  public allSelected: boolean = false;

  constructor(
    private cognito: CognitoService,
    private apiService: ApiService
  ) {}

  async ngOnInit() {
    this.loadUsers(); // Initial load
  }

  async loadUsers() {
    this.loading = true;
    this.users = [];
    const newUsers = await this.cognito.getAllUsers();

    if (newUsers) {
      this.users = [...this.users, ...newUsers]; // Append new users
      this.visibleUsers = [...this.users]; // Display them in the list
      this.currentPage++; // Increment page number for next load
    }

    this.loading = false;
  }

  async filterUsers() {
    const query = this.searchQuery.toLowerCase();
    this.visibleUsers = this.users.filter(user =>
      ['username', 'email', 'given_name', 'family_name'].some(field =>
        (user[field as keyof CognitoUser] || '').toString().toLowerCase().includes(query)
      )
    );
    this.sortUsers();
  }

  async sortUsers() {
    this.visibleUsers.sort((a, b) => {
      const fieldA = (a[this.sortField] || '').toString().toLowerCase();
      const fieldB = (b[this.sortField] || '').toString().toLowerCase();

      if (fieldA < fieldB) return this.sortOrder === 'up' ? -1 : 1;
      if (fieldA > fieldB) return this.sortOrder === 'up' ? 1 : -1;
      return 0;
    });
  }

  async setSortField(field: keyof CognitoUser) {
    this.loading = true;
    if (this.sortField === field) {
      // Toggle sort order if the same field is clicked
      this.sortOrder = this.sortOrder === 'up' ? 'down' : 'up';
    } else {
      this.sortField = field;
      this.sortOrder = 'up'; // Default to ascending order for new fields
    }
    await this.sortUsers();
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  toggleSelectAll() {
    if (this.allSelected) {
      // If allSelected is true, deselect all users
      this.selectedUsers = [];
      this.allSelected = false; // Explicitly set to false
    } else {
      // If allSelected is false, select all visible users
      this.selectedUsers = [...this.visibleUsers];
      this.allSelected = true; // Explicitly set to true
    }
  }


  // Method to toggle selection of individual users
  toggleUserSelection(user: CognitoUser) {
    const index = this.selectedUsers.findIndex(selected => selected.username === user.username);
    if (index === -1) {
      this.selectedUsers.push(user);
    } else {
      this.selectedUsers.splice(index, 1);
    }
    // Update "Select All" state
    this.allSelected = this.selectedUsers.length === this.visibleUsers.length;
  }

  // Existing method for single user selection
  onUserSelect(user: CognitoUser) {
    this.selectedUser = user;
    console.log('Selected user:', this.selectedUser);
  }

  // Method for tracking users in *ngFor
  trackByUserId(index: number, user: CognitoUser): string {
    return user.username; // Use a unique identifier, e.g., username or user ID
  }

  async deleteSelectedUsers() {
    if (!confirm('Are you sure you want to delete the selected users?')) {
      return;
    }

    const usernames = this.selectedUsers.map(user => user.username); // Collect usernames to delete
    console.log(usernames);
    try {
      this.loading = true;
      await this.apiService.deleteSelectedUser(usernames); // Call the API service
      this.selectedUsers = []; // Clear selected users
      this.loadUsers(); // Reload the user list
      console.log('Selected users deleted successfully.');
    } catch (error) {
      console.error('Error deleting users:', error);
      console.log('An error occurred while deleting users.');
    } finally {
      this.loading = false;
    }
  }




}
