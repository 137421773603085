import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { JwtDecodeService } from './jwt-decode.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, firstValueFrom, map, throwError  } from 'rxjs';
import { SYUSER } from './cognito.service';

export interface userCallStructure{
  data: SYUSER[]
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public token: string = '';
  public redirect: string = '';
  constructor(
    private http: HttpClient,
    private decodeTokenService: JwtDecodeService) { }

  /**
   * Authenticates the user with the provided email.
   * @param email The email of the user to authenticate.
   * @returns An Observable emitting the redirect destination upon successful authentication or an error message.
  */

   async authenticateUser(email: string): Promise<string | boolean> {
    const url = `${environment.api.cdn}${environment.api.route.authenticate}`;
    const payload = { email: email };


      const response = await firstValueFrom(this.http.post<any>(url, payload));

      const redirectDestination = response.redirect;
      this.redirect = redirectDestination;
      this.token = response.token;
      const ulock  = parseInt(response.ulock);
     //console.log(ulock)

      if(ulock === 1){
        return false;
      }

      return redirectDestination;

  }

 // Function to get all SYUSERs and return them as a promise
 async getSYUSERS(): Promise<SYUSER[]> {
    const url = `${environment.api.cdn}${environment.api.route.getUsers}`;

    try {
      const response: userCallStructure = await firstValueFrom(this.http.get<userCallStructure>(url));
      return response.data;  // This will return the array of SYUSERs
    } catch (error) {
      console.error('Error fetching SYUSER data:', error);
      throw new Error('Failed to fetch SYUSER data');
    }
  }
  // Function to delete selected users
  async deleteSelectedUser(usernames: string[]): Promise<void> {
    const url = `${environment.api.cdn}${environment.api.route.deleteUsers}`; // Replace with the actual API route for deleting users

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Adjust content type as needed
    });

    try {
      // Send the list of usernames to the API
      await firstValueFrom(
        this.http.post<void>(url, { usernames })
      );
      console.log('Selected users deleted successfully.');
    } catch (error) {
      console.error('Error deleting selected users:', error);
      throw new Error('Failed to delete selected users');
    }
  }


}
