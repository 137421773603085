<link href="https://cdn.jsdelivr.net/npm/bootstrap-icons/font/bootstrap-icons.css" rel="stylesheet">
<app-loading-element *ngIf="loading" class="container-fluido componentBelow"></app-loading-element>
<section *ngIf="!loading">

<div class="container-fluid mt-4">
  <h1 class="mb-4">
    User Management
    <span class="">({{ users.length }})</span>
  </h1>

  <div class="mb-3">
    <button class="btn btn-primary" (click)="deleteSelectedUsers()">Log Selected Users</button>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <input
        type="text"
        class="form-control"
        placeholder="Search by username, email, first name, or last name..."
        [(ngModel)]="searchQuery"
        (ngModelChange)="filterUsers()"
      />
    </div>

    <div class="col-md-4 offset-md-2">
      <select
        class="form-select"
        [(ngModel)]="sortField"
        (change)="sortUsers()">
        <option value="username">Username</option>
        <option value="email">Email</option>
        <option value="given_name">First Name</option>
        <option value="family_name">Last Name</option>
      </select>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-hover">
      <thead class="table-light">
        <tr>
          <!-- "Select All" Checkbox -->
          <th>
            <input
              type="checkbox"
              [checked]="allSelected"
              (change)="toggleSelectAll()"
            />
          </th>
          <th (click)="setSortField('username')">
            Username <i [class]="'bi bi-sort-' + (sortField === 'username' ? sortOrder : 'up')"></i>
          </th>
          <th (click)="setSortField('email')">
            Email <i [class]="'bi bi-sort-' + (sortField === 'email' ? sortOrder : 'up')"></i>
          </th>
          <th (click)="setSortField('given_name')">
            First Name <i [class]="'bi bi-sort-' + (sortField === 'given_name' ? sortOrder : 'up')"></i>
          </th>
          <th (click)="setSortField('family_name')">
            Last Name <i [class]="'bi bi-sort-' + (sortField === 'family_name' ? sortOrder : 'up')"></i>
          </th>
          <th (click)="setSortField('user_status')">
            Status <i [class]="'bi bi-sort-' + (sortField === 'user_status' ? sortOrder : 'up')"></i>
          </th>
          <th>
            Location <i [class]="'bi'"></i>
          </th>
        </tr>
      </thead>
      <tbody style="max-height: 70vh; overflow-y: auto; border: 1px solid #dee2e6;">
        <tr *ngFor="let user of visibleUsers; trackBy: trackByUserId">
          <!-- Checkbox for each user -->
          <td>
            <input
              type="checkbox"
              [checked]="selectedUsers.includes(user)"
              (change)="toggleUserSelection(user)"
            />
          </td>
          <td>{{ user.username }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.given_name }}</td>
          <td>{{ user.family_name }}</td>
          <td>{{ user.user_status }}</td>
          <td>{{ user.syuser?.location }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="visibleUsers.length === 0" class="mt-3">
    <p class="text-center">No users found.</p>
  </div>
</div>
</section>
